/*global Component */
class EditProfileComponentB2C extends Component {
    static name() {
        return "editProfileB2C";
    }

    static componentName() {
        return "editProfileB2C";
    }

    getProps() {
        return {
            errorMsg: {
                type: String,
                default:null
            }, 
        };
    }


    getWatch() {
        return {
            "session":function (){
                if(this.session) {
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_Province', value: this.session.fields.ProvinceCode});
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_Country', value: this.session.fields.Country});
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_ZipCode', value: this.session.fields.ZipCode});
                    this.$store.dispatch('updateSelectLinkto', {table: 'Session_CenterCost', value: this.session.fields.CenterCost});
                }
            },
            "$route.params.activeView":function (newValue,oldValue){
                if(oldValue != newValue)
                    this.changeView(newValue);

            }
        };
    }

    getComputed() {
        return {
            stringPattern:function (){
                return this.$store.getters.getPhoneMaskPattern;
            },
            phonePattern: function (){
                return new RegExp(this.stringPattern);
            },
            session: function () {
                let storeSession = this.$store.getters.getSession;
                if(storeSession)
                    return storeSession;
                return null;
            },
        };
    }

    getMethods() {
        return {
            profileSaveEvent: this.profileSaveEvent,
            updateSelectValues: this.updateSelectValues
        };
    }

    profileSaveEvent() {
        this.emitEvent('profileSaveB2C', this.session);
    }

    updateSelectValues(){
        this.session.fields.ProvinceCode = this.$store.getters.getSelectLinkToValue('Session_Province');
        this.session.fields.ZipCode = this.$store.getters.getSelectLinkToValue('Session_ZipCode');
        this.session.fields.Country = this.$store.getters.getSelectLinkToValue('Session_Country');
        this.session.fields.DefaultCenterCost = this.$store.getters.getSelectLinkToValue('Session_CenterCost');
    }


    getTemplate() {
        return `<div id="Profile" >
                <h5 class="my-4">{{tr('Personal Details')}}</h5>
                <div class="content-block">
                    <div ref='errorEditProfile' class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errorAlertDataSession">
                        <strong>{{tr('Error')}}: {{tr(errorAlertDataSession)}}!</strong>
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="errorAlertDataSession = ''">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                
                            <div class="form-group">
                                <label for="firstname" class="form-label">{{tr('Name')}}</label>
                                <input id="firstname" type="text" class="form-control" v-model="session.fields.Name" >
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="lastname" class="form-label">{{tr('Last Name')}}</label>
                                <input id="lastname" type="text" class="form-control" v-model="session.fields.LastName" >
                            </div>
                        </div>
                    </div>
                    <!-- /.row-->
                    <div class="row">
                        <div class="col-sm-6 d-none">
                            <div class="form-group">
                                <label for="WorkAddress" class="form-label">{{tr('Address')}}</label>
                                <input id="WorkAddress" type="text" class="form-control" v-model="session.fields.WorkAddress">
                            </div>
                        </div>
                        <div class="col-sm-6 d-none">
                            <div class="form-group">
                                <label for="Province" class="form-label">{{tr('Province')}}</label>
                                <selectLinkToComponent  recordKey="Session" table="Province" paste="Code" showField="Name" :selected="session.fields.ProvinceCode" v-on:update-value="updateSelectValues"> </selectLinkToComponent>
                            </div>
                        </div>
                        <div class="col-sm-6 d-none">
                            <div class="form-group">
                                <label for="zip" class="form-label">{{tr('ZIP')}}</label>
                                <selectLinkToComponent recordKey="Session" table="ZipCode" paste="Code" showField="Code" :selected="session.fields.ZipCode" v-on:update-value="updateSelectValues"> </selectLinkToComponent>
                            </div>
                        </div>
                        <div class="col-sm-6 d-none">
                            <div class="form-group">
                                <label for="company" class="form-label">{{tr('Country')}}</label>
                                <selectLinkToComponent recordKey="Session" table="Country" paste="Code" showField="Name" :selected="$store.getters.getSelectLinkToValue('Country')" v-on:update-value="updateSelectValues"> </selectLinkToComponent>
                            </div>
                        </div>
                
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="phone" class="form-label">{{tr('Personal Phone')}}</label>
                                <input id="phone" type="text" class="form-control"  v-model="session.fields.Phone" aria-autocomplete="none" autocomplete="none" placeholder="+549XXXXXXXXX" :pattern="phonePattern.source">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="email" class="form-label">{{tr('Mail')}} (Se utilizará como Usuario)</label>
                                <input id="email" type="email" class="form-control" v-model="session.fields.Email">
                            </div>
                        </div>
                        <div class="col-sm-12 text-right">
                            <button class="btn btn-custom save-changes" @click="EditProfileSession"><span class="icon"><i class="fa-icon fa fa-save mr-1"></i></span>  {{tr('Save Changes')}}</button>
                        </div>
                    </div>
                </div>
            </div>`;
    }
}

EditProfileComponentB2C.registerComponent();
